export const companyProfileRedirection = (
  industry: string,
  friendlyURL: string,
  lng: string
) => {
  if (industry === 'AF Transports') {
    return `/${lng}/transport_logistics/${friendlyURL}/profile`
  } else if (industry === 'AF Pre & Post Harvest') {
    return `/${lng}/pre-post-harvest/${friendlyURL}/profile`
  } else {
    return `/${lng}/fruits_and_vegetables/${friendlyURL}/profile`
  }
}

export const sessionClear = (redirectUrl?: string) => {
  if (process.env.NODE_ENV === 'development') {
    window.location.href = `${process.env.API_LOCALHOST_ORIGIN_URL}/auth/logout${redirectUrl ? `?redirect_to=${redirectUrl}` : ''}`
  } else {
    window.location.href =
      window.location.origin +
      '/auth/logout' +
      `${redirectUrl ? `?redirect_to=${redirectUrl}` : ''}`
  }
}
